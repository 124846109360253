import React from "react";
import { openNotification } from "../functions";
import { Button, Modal, Table, Input, Tooltip, Popconfirm } from "antd";

export default class Clients extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activeBlock: 0,
      clients: []
    };
  }

  ChangeInputValue = (e) => {
    let name = e.target.name;
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({ [name]: value });
  }

  getClients() {
    fetch(`https://greenapple-delivery.ru/api/clients`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'Bearer ' + localStorage.getItem('griaple_t_o')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
          if(response.error == 'tokenErrorAuth') {
            window.location.href = "http://localhost:3000"
          }
        } else if (!response.data) {
          openNotification('Ошибка', "Непредвиденная ошибка, обратитесь к разработчику", 1, 4.5);
        } else {
          this.setState({ clients: response.data });
        }
      })
  }

  render() {
    const columns = [
      {
        title: 'Никнейм telegram',
        dataIndex: 'tg_username',
        key: 'tg_username',
      },
      {
        title: 'Имя',
        dataIndex: 'user_name',
        key: 'user_name',
      },
      {
        title: 'Фамилия',
        dataIndex: 'user_surname',
        key: 'user_surname',
      }
    ];
    return (
      <div className="content">
        <div className="page__header">
          <div className="pageTitle">Список клиентов</div>
        </div>
        <Table
            dataSource={this.state.clients} columns={columns}
          />
      </div>
    );
  }

  componentDidMount() {
    this.getClients();
  }

}