
import React from "react";
import { InboxOutlined, EyeOutlined } from '@ant-design/icons';
import { message, Upload, Modal, Tooltip, Select, Table } from 'antd';

import * as xlsx from 'xlsx';
import {
  DeleteOutlined,
} from '@ant-design/icons';
import { openNotification } from "../functions";
import moment from 'moment';

const { Dragger } = Upload;

export default class Main extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activeBlock: 0,
      uploadFileJson: [],
      loadInput: "",
      modal: false,
      points: [],
      selectPoint: 0,
      menus: 0,
      selectedMenu: null,
      selectedMenuModal: false
    };
  }


  uploadFile(el) {
    const reader = new FileReader();
    reader.readAsArrayBuffer(el.file.originFileObj);

    reader.onload = (e) => {
      this.getPoints();
      const data = e.target.result;
      const workbook = xlsx.read(data, { type: "array" });
      const workbookHeaders = xlsx.readFile(data, { sheetRows: 1 });
      const sheetName2 = workbookHeaders.SheetNames;
      const columnsArray = xlsx.utils.sheet_to_json(workbookHeaders.Sheets[sheetName2[0]], { header: 1 })[0];
      const sheetName = workbook.SheetNames;
      const worksheet = workbook.Sheets[sheetName[0]];
      const json = xlsx.utils.sheet_to_json(worksheet);

      this.setState({ uploadFileJson: json, modal: true });
     
    };
  }


  postProduct() {

    if(this.state.selectPoint == 0) {
      return openNotification('Ошибка', 'Выберите точку продаж', 1, 4.5);
    }
    let pp = '';
    this.state.uploadFileJson.map((elem) => {
      if(!elem.kkal) {
        pp = elem.name;
      }else{
        elem.cat = pp;
      }
    })
    fetch(`https://greenapple-delivery.ru/api/orders/add/menu`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'Bearer ' + localStorage.getItem('griaple_t_o')
      }),
      method: 'POST',
      cache: 'no-cache',
      body: JSON.stringify({
        point: this.state.selectPoint,
        menu: this.state.uploadFileJson
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else if (!response.data) {
          openNotification('Ошибка', "Непредвиденная ошибка, обратитесь к разработчику", 1, 4.5);
        } else {
          openNotification('Успех', response.data, 3, 4.5);
        }
      })
  }

  deletePosition(i) {
    let arr = this.state.uploadFileJson;
    arr.splice(i, 1);
    this.setState({uploadFileJson: arr});
    console.log(arr);
  }

  getMenu(){
    fetch(`https://greenapple-delivery.ru/api/orders/menu`, {
            headers: new Headers({
              'Content-Type': 'application/json; charset=utf-8',
              'Authorization': 'Bearer ' + localStorage.getItem('griaple_t_o')
            }),
            method: 'GET',
            cache: 'no-cache',
          }).then(response => response.json())
          .then(response => {
            if (response.error) {
              openNotification('Ошибка', response.error, 1, 4.5);
              if(response.error == 'tokenErrorAuth') {
                window.location.href = "http://localhost:3000"
              }
            } else if (!response.data) {
              openNotification('Ошибка', "Непредвиденная ошибка, обратитесь к разработчику", 1, 4.5);
            } else {
              let arr = [];
              response.data.map((elem, index) => {
                arr.push({
                  id: elem.id,
                  date_M: moment(elem.date_M).format('DD-MM-YYYY HH:MM'),
                  menu: elem.menu,
                  point: elem.point,
                  point_id: elem.point_id,
                  point_name: elem.point_name,
                  point_address: elem.point_address,
                  point_state: elem.point_state,
                  event: <Tooltip title="Смотреть"><EyeOutlined onClick={() => {this.openMenu(index)}} style={{cursor: 'pointer'}}/></Tooltip>
                })
              })
              this.setState({ menus: arr });
            }
          })
  }

  openMenu(index) {
    this.setState({selectedMenu: this.state.menus[index], selectedMenuModal: true})
    console.log(this.state.selectedMenu);
  }

  getPoints() {
    fetch(`https://greenapple-delivery.ru/api/points`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'Bearer ' + localStorage.getItem('griaple_t_o')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else if (!response.data) {
          openNotification('Ошибка', "Непредвиденная ошибка, обратитесь к разработчику", 1, 4.5);
        } else {
          this.setState({ points: response.data });
        }
      })
  }

  render() {
    const columns = [
      {
        title: 'Дата публикации',
        dataIndex: 'date_M',
        key: 'date_M',
      },
      {
        title: 'Название точки',
        dataIndex: 'point_name',
        key: 'point_name',
      },
      {
        title: 'Адрес точки',
        dataIndex: 'point_address',
        key: 'point_address',
      },
      {
        title: '',
        dataIndex: 'event',
        key: 'event'
      }
    ];
    return (
      <div className="content">
        <div className="page__header">
          <div className="pageTitle">Меню</div>
        </div>
        <Dragger showUploadList={false} name={'file'} onChange={(e) => { this.uploadFile(e); }}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Нажмите чтобы открыть или перенесите файл и с меню в это поле</p>
        </Dragger>
        <div className="page__header" style={{marginTop: 30}}>
          <div className="pageTitle">Опубликованные меню</div>
        </div>
        <Table
            dataSource={this.state.menus} columns={columns}
          />

        <Modal width={800} title="Информация о меню" open={this.state.selectedMenuModal} footer={[]} onCancel={() => {this.setState({selectedMenuModal: false})}}>
          {this.state.selectedMenu != null ? 
            <div>
              {this.state.selectedMenu.date_M}
              {JSON.parse(this.state.selectedMenu.menu).map((elem, index) => {
                return <div key={index}>{elem.kkal ? "•" : ""} {elem.kkal ? elem.name : <b>{elem.name}</b>} {elem.kkal ? "" : ":"} {elem.kkal} {elem.price}</div>
              })}
            </div>
          : ""}
        </Modal>
        <Modal width={800} title="Позиции" open={this.state.modal} onOk={() => {this.postProduct();}} onCancel={() => {this.setState({modal: false})}}>
          {

            this.state.uploadFileJson.length == 0 ?
            ""
            :
            <div>
              {
               this.state.uploadFileJson.map((elem, index) => {
                if(!elem.kkal) {
                  return(
                    <div key={index}><b>{elem.name}</b><Tooltip title="Удалить категорию"><DeleteOutlined onClick={() => {this.deletePosition(index)}}/></Tooltip></div>
                  )
                }else{
                  return(
                    <div key={index}>{elem.name} -- {elem.kkal} -- {elem.gr} -- {elem.price} <Tooltip title="Удалить позицию"><DeleteOutlined onClick={() => {this.deletePosition(index)}}/></Tooltip></div>
                  )
                }
              })
              }
              <h2>Выберите точку</h2>
              <Select onChange={(value) => this.setState({selectPoint: value})} style={{width: '100%'}}>
                {
                  this.state.points.length == 0 ?
                  ""
                  :
                  this.state.points.map((point, index) => {
                    return(<Select.Option key={point.point_id}>{point.point_name} -- {point.point_address}</Select.Option>)
                  })
                }
                
              </Select>
            </div>
          }
        </Modal>
      </div>
    );
  }

  componentDidMount() {
    this.getMenu();
  }

}