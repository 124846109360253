import './App.css';
import React from 'react';

import { Link, Route, Routes } from 'react-router-dom';
import { Layout } from 'antd';

import Points from './pages/Points';
import Main from './pages/Main';
import Login from './pages/Login';
import Clients from './pages/Clients';
import Orders from './pages/Orders';

const { Header } = Layout;

class App extends React.Component {
  render() {
    return (
      <div className="App">
        {
          localStorage.getItem("griaple_t_o") ?
            <Header style={{ display: 'flex', alignItems: 'center' }}>
              <div className='titleAPP'>INNOPOLISFOOD | GREENAPPLE</div>
              <Link to={'/'}>Меню</Link>
              <Link to={'/orders'}>Заказы</Link>
              <Link to={'/clients'}>Клиенты</Link>
              <Link to={'/points'}>Точки продаж</Link>
            </Header>
            :
            ""
        }
        <div className='wrapper'>
          <div className='wrapper__content'>
            <Routes>
              {
                localStorage.getItem("griaple_t_o") ?
                  <>
                  <Route path="/" element={<Main />}></Route>
                  <Route path="/points" element={<Points />}></Route>
                  <Route path="/clients" element={<Clients />}></Route>
                  <Route path="/orders" element={<Orders />}></Route>
                  </>
                  :
                  <Route path="/" element={<Login />}></Route>
              }
            </Routes>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
