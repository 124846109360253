import { Button, Modal, Table, Input, Tooltip, Popconfirm, Slider } from "antd";
import React from "react";
import { openNotification } from "../functions";
import { EyeTwoTone, EyeInvisibleTwoTone, QuestionCircleOutlined, SettingOutlined } from '@ant-design/icons';
import moment from "moment";

export default class Points extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      modalAdd: false,
      pointName: "",
      pointAddress: "",
      points: [],
      selectedPoint: null,
      selectedPointModal: false
    };
  }

  ChangeInputValue = (e) => {
    let name = e.target.name;
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({ [name]: value });
  }

  getPoints() {
    fetch(`https://greenapple-delivery.ru/api/points`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'Bearer ' + localStorage.getItem('griaple_t_o')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
          if(response.error == 'tokenErrorAuth') {
            window.location.href = "http://localhost:3000"
          }
        } else if (!response.data) {
          openNotification('Ошибка', "Непредвиденная ошибка, обратитесь к разработчику", 1, 4.5);
        } else {
          let arr = [];
          response.data.map((elem) => {
            arr.push({
              point_id: elem.point_id,
              point_name: elem.point_name,
              point_address: elem.point_address,
              point_state: elem.point_state,
              start_sales: elem.start_sales,
              end_sales: elem.end_sales,
              start_issue: elem.start_issue,
              end_issue: elem.end_issue,
              timeSet: <div>Прием: {elem.start_sales}:00 - {elem.end_sales}:00<br/>Вывоз: {elem.start_issue}:00 - {elem.end_issue}:00<br/><Tooltip title="Настроить"><SettingOutlined onClick={() => {this.setState({selectedPoint: elem, selectedPointModal: true})}} style={{cursor: 'pointer'}} /></Tooltip></div>
            })
          })
          this.setState({ points: arr });
        }
      })
  }

  addPoint() {
    if(!this.state.pointName.trim() || !this.state.pointAddress.trim()) {
      return(openNotification('Ошибка', 'Пожалуйста заполните все поля', 1, 4.5));
    }
    fetch(`https://greenapple-delivery.ru/api/points`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'Bearer ' + localStorage.getItem('griaple_t_o')
      }),
      method: 'POST',
      cache: 'no-cache',
      body: JSON.stringify({
        name: this.state.pointName,
        address: this.state.pointAddress
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else if (!response.data) {
          openNotification('Ошибка', "Непредвиденная ошибка, обратитесь к разработчику", 1, 4.5);
        } else {
          openNotification('Успех', response.data, 3, 4.5);
          this.setState({pointName: "", pointAddress: "", modalAdd: false});
          this.getPoints();
        }
      })
  }

  saveTime() {
    fetch(`https://greenapple-delivery.ru/api/points/time/` + this.state.selectedPoint.point_id, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'Bearer ' + localStorage.getItem('griaple_t_o')
      }),
      method: 'PATCH',
      cache: 'no-cache',
      body: JSON.stringify({
        start_sales: this.state.selectedPoint.start_sales,
        end_sales: this.state.selectedPoint.end_sales,
        start_issue: this.state.selectedPoint.start_issue,
        end_issue: this.state.selectedPoint.end_issue
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else if (!response.data) {
          openNotification('Ошибка', "Непредвиденная ошибка, обратитесь к разработчику", 1, 4.5);
        } else {
          openNotification('Успех', response.data, 3, 4.5);
          this.setState({selectedPointModal: false});
          this.getPoints();
        }
      })
  }

  changeStatePoint(id, state) {
    fetch(`https://greenapple-delivery.ru/api/points/${id}`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'Bearer ' + localStorage.getItem('griaple_t_o')
      }),
      method: 'PATCH',
      cache: 'no-cache',
      body: JSON.stringify({
        state: state
      })
    })
    .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else if (!response.data) {
          openNotification('Ошибка', "Непредвиденная ошибка, обратитесь к разработчику", 1, 4.5);
        } else {
          openNotification('Успех', response.data, 3, 4.5);
          this.getPoints();
        }
      })
  }

  render() {

    const columns = [
      {
        title: 'Название',
        dataIndex: 'point_name',
        key: 'point_name',
      },
      {
        title: 'Адрес',
        dataIndex: 'point_address',
        key: 'point_address',
      },
      {
        title: 'Время работы',
        dataIndex: 'timeSet',
        key: 'timeSet'
      },
      {
        title: 'Статус',
        dataIndex: 'point_state',
        key: 'point_state',
        render: (text, rec) => {
          return (
            rec.point_state === 0 ?
              <Tooltip title="Включить">
                <Popconfirm
                  title="Вы действительно хотите включить точку продаж?"
                  description="Клиенты увидят точку в списке при оформлении заказа"
                  onConfirm={() => { this.changeStatePoint(rec.point_id, 1); }}
                  okText="Включить"
                  cancelText="Отмена"
                >
                  <EyeInvisibleTwoTone twoToneColor="#eb2f96" />
                </Popconfirm>
              </Tooltip>
              :
              <Tooltip title="Выключить">
                <Popconfirm
                  title="Вы действительно хотите выключить точку продаж?"
                  description="Клиенты не увидят точку в списке при оформлении заказа"
                  onConfirm={() => { this.changeStatePoint(rec.point_id, 0); }}
                  okText="Выключить"
                  cancelText="Отмена"
                  icon={
                    <QuestionCircleOutlined
                      style={{
                        color: 'red',
                      }}
                    />
                  }
                >
                  <EyeTwoTone twoToneColor="#52c41a" />
                </Popconfirm>
              </Tooltip>
          )
        }
      }
    ];

    return (
      <div className="content">
        <div className="page__header">
          <div className="pageTitle">Точки продаж</div>
          <Button type="primary" onClick={() => { this.setState({ modalAdd: true }) }}>Добавить точку</Button>
        </div>
        <Table
          dataSource={this.state.points} columns={columns}
        />

        <Modal
          title="Добавить точку"
          open={this.state.modalAdd}
          onCancel={() => { this.setState({ modalAdd: false }) }}
          footer={
            [
              <Button onClick={() => { this.setState({ modalAdd: false }) }}>Отмена</Button>,
              <Button type="primary" onClick={() => { this.addPoint(); }}>Добавить</Button>
            ]
          }
        >
          <div className="input_name">Название</div>
          <Input name="pointName" onChange={this.ChangeInputValue.bind(this)} value={this.state.pointName} />
          <div className="input_name">Адрес</div>
          <Input name="pointAddress" onChange={this.ChangeInputValue.bind(this)} value={this.state.pointAddress} />
        </Modal>

        <Modal
          title="Настройки времени"
          open={this.state.selectedPointModal}
          onCancel={() => { this.setState({ selectedPointModal: false }) }}
          footer={
            [
              <Button onClick={() => { this.setState({ selectedPointModal: false }) }}>Отмена</Button>,
              <Button type="primary" onClick={() => { this.saveTime(); }}>Добавить</Button>
            ]
          }
        >
          {
            this.state.selectedPoint == null ? 
            ""
            :
            <div>
              <div>Время приема заказов {this.state.selectedPoint.start_sales}:00 - {this.state.selectedPoint.end_sales}:00</div>
              <Slider max={24} min={0} range defaultValue={[this.state.selectedPoint.start_sales, this.state.selectedPoint.end_sales]} onChange={(values) => {let vl = this.state.selectedPoint; vl.start_sales = values[0]; vl.end_sales = values[1]; this.setState({selectedPoint: vl})}} />

              <div>Время вывоза заказов {this.state.selectedPoint.start_issue}:00 - {this.state.selectedPoint.end_issue}:00</div>
              <Slider max={24} min={0} range defaultValue={[this.state.selectedPoint.start_issue, this.state.selectedPoint.end_issue]} onChange={(values) => {let vl = this.state.selectedPoint; vl.start_issue = values[0]; vl.end_issue = values[1]; this.setState({selectedPoint: vl})}} />
            </div>
          }
        </Modal>
      </div>
    );
  }

  componentDidMount() {
    this.getPoints();
  }

}