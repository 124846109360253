import React from "react";
import { Button, Form, Input } from 'antd';
import { openNotification } from "../functions";

export default class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activeBlock: 0,
      login: "",
      password: ""
    };
  }

  login(){
    fetch(`https://greenapple-delivery.ru/api/login`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
      }),
      method: 'POST',
      cache: 'no-cache',
      body: JSON.stringify({
        login: this.state.login,
        password: this.state.password
      })
    })
    .then(response => response.json())
    .then(response => {
      if (response.error) {
        openNotification('Ошибка', response.error, 1, 4.5);
      } else if (!response.token) {
        openNotification('Ошибка', "Непредвиденная ошибка, обратитесь к разработчику", 1, 4.5);
      } else {
        localStorage.setItem("griaple_t_o", response.token);
        window.location.href="/";
      }
    })
  }

  ChangeInputValue = (e) => {
    let name = e.target.name;
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({ [name]: value });
  }
  render() {

    return (
      <div className="login_wrapper">
        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            maxWidth: 600,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={() => {this.login()}}
          autoComplete="off"
        >
          <Form.Item
            label="Логин"
            name="username"
            rules={[
              {
                required: true,
                message: 'Пожалуйста введите ваш логин!',
              },
            ]}
          >
            <Input name="login" onChange={this.ChangeInputValue.bind(this)} value={this.state.login} />
          </Form.Item>

          <Form.Item
            label="Пароль"
            name="password"
            rules={[
              {
                required: true,
                message: 'Пожалуйста введите ваш пароль!',
              },
            ]}
          >
            <Input.Password name="password" onChange={this.ChangeInputValue.bind(this)} value={this.state.password} />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button type="primary" htmlType="submit">
              Войти
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }

  componentDidMount() {
  }

}