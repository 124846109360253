import React from "react";
import { openNotification } from "../functions";
import { Button, Modal, Table, Input, Tooltip, Popconfirm } from "antd";
import moment from 'moment';
import {
  CheckCircleOutlined,
} from '@ant-design/icons';

export default class Orders extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activeBlock: 0,
      orders: [],
      menus: []
    };
  }

  ChangeInputValue = (e) => {
    let name = e.target.name;
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({ [name]: value });
  }

  getFilters() {
    let filters = [];
    filters.point = this.state.orders?.map(sell => sell.point).filter((val, index, arr) => val && arr.indexOf(val) === index).map(point => { return { text: point, value: point } });
    filters.data_order = this.state.orders?.map(sell => sell.data_order).filter((val, index, arr) => val && arr.indexOf(val) === index).map(data_order => { return { text: data_order, value: data_order } });
    filters.status = this.state.orders?.map(sell => sell.status).filter((val, index, arr) => val && arr.indexOf(val) === index).map(status => { return { text: status, value: status } });
    filters.status_order = this.state.orders?.map(sell => sell.status_order).filter((val, index, arr) => val && arr.indexOf(val) === index).map(status_order => { return { text: status_order, value: status_order } });
    return filters;
  }

  setStatusComplite(id){
    fetch(`https://greenapple-delivery.ru/api/orders/` + id + "/edit", {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'Bearer ' + localStorage.getItem('griaple_t_o')
      }),
      method: 'PATCH',
      cache: 'no-cache',
    })
    .then(response => response.json())
    .then(response => {
      if (response.error) {
        openNotification('Ошибка', response.error, 1, 4.5);
      }else{
        this.getOrders();
      }
    })
  }

  getOrders() {
    fetch(`https://greenapple-delivery.ru/api/orders`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'Bearer ' + localStorage.getItem('griaple_t_o')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
          if(response.error == 'tokenErrorAuth') {
            window.location.href = "http://localhost:3000"
          }
        } else if (!response.data) {
          openNotification('Ошибка', "Непредвиденная ошибка, обратитесь к разработчику", 1, 4.5);
        } else {

          let arr = [];
          response.data.map((elem) => {

            let menu = JSON.parse(elem.menu);
            let prods = elem.products.substring(0, elem.products.length - 1).split(',');
            let prodsStr = '';
            prods.map((prod) => {
              prodsStr += menu[prod]?.name + "=" + menu[prod]?.price + "||";
            });

            prodsStr = prodsStr.substring(0, prodsStr.length - 2);
            let OpayStatus = ''
            switch (elem.paystatus) {
              case 'Неизвестно':
                OpayStatus = "Заказ создан, не оплачен"
                break;
              case 'none':
                OpayStatus = "Заказ отменен"
                break;
              case 'succeeded':
                OpayStatus = "Заказ оплачен"
                break;
              default:
                OpayStatus = elem.paystatus;
            }
            let orderStatus = '';
            switch (elem.status) {
              case 1:
                orderStatus = "В работе"
                break;
              case 0:
                orderStatus = "Отменен"
                break;
              case 2:
                orderStatus = "Заказ готов"
                break;
              default:
                orderStatus = elem.status;
            }

            arr.push({
              order_id: elem.order_id,
              client: <div>tg: {elem.tg_username}<br/>Имя: {elem.user_name}<br/>Почта: {elem.mail}</div>,
              point: elem.point_name + `\n` + elem.point_address,
              price: elem.price,
              data_order: moment(elem.date).format('DD-MM-YYYY'),
              date_time: moment(elem.date).format('HH:MM'),
              status: OpayStatus,
              products: prodsStr,
              event: orderStatus == "Заказ готов" ? "" : <Tooltip title="Уставить статус готовности?">{OpayStatus == "Заказ оплачен" ? 
              <Popconfirm
                title="Уставить статус готовности?"
                description={"Вы уверены, что хотите установить статус \"Заказ готов\"?\nКлиенту придет уведомление о готовности"}
                onConfirm={() => {this.setStatusComplite(elem.order_id);}}
                onCancel={() => {}}
                okText="Да"
                cancelText="Нет"
              >
                <CheckCircleOutlined />
              </Popconfirm> : ""}</Tooltip>,
              status_order: orderStatus
            });
          });

          this.setState({ orders: arr });


        }
      })
  }

  render() {

    let filters = this.getFilters();

    const columns = [
      {
        title: 'Номер заказа',
        dataIndex: 'order_id',
        key: 'order_id',
      },
      {
        title: 'Клиент',
        dataIndex: 'client',
        key: 'client',
      },
      {
        title: 'Точка продаж',
        dataIndex: 'point',
        key: 'point',
        filters: filters.point || [],
        onFilter: (value, record) => record?.point ? record.point.indexOf(value) === 0 : null,
        sorter: (a, b) => ('' + a.point).localeCompare(b.point),
        render: (text, record) => {
          return text;
        },
      },
      {
        title: 'Товары',
        dataIndex: 'products',
        key: 'products',
      },
      {
        title: 'Сумма',
        dataIndex: 'price',
        key: 'price',
      },
      {
        title: 'Дата заказа',
        dataIndex: 'data_order',
        key: 'data_order',
        filters: filters.data_order || [],
        onFilter: (value, record) => record?.data_order ? record.data_order.indexOf(value) === 0 : null,
        sorter: (a, b) => ('' + a.data_order).localeCompare(b.data_order),
        render: (text, record) => {
          return text;
        }
      },
      {
        title: 'Время заказа',
        dataIndex: 'date_time',
        key: 'date_time',
      },
      {
        title: 'Статус оплаты',
        dataIndex: 'status',
        key: 'status',
        filters: filters.status || [],
        onFilter: (value, record) => record?.status ? record.status.indexOf(value) === 0 : null,
        sorter: (a, b) => ('' + a.status).localeCompare(b.status),
        render: (text, record) => {
          return text;
        }
      },
      {
        title: 'Статус заказа',
        dataIndex: 'status_order',
        key: 'status_order',
        filters: filters.status_order || [],
        onFilter: (value, record) => record?.status_order ? record.status_order.indexOf(value) === 0 : null,
      },
      {
        title: '',
        dataIndex: 'event',
        key: 'event'
      }
    ];
    return (
      <div className="content">
        <div className="page__header">
          <div className="pageTitle">Заказы</div>
          <Button onClick={() => {this.getOrders()}}>Обновить</Button>
        </div>
        <div className="table_place">
        <Table
          dataSource={this.state.orders} columns={columns} 
        />
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.getOrders();
    timerId = setInterval(() => this.getOrders(), 30000);
  }

  componentWillUnmount() {
    clearInterval(timerId)
  }
}

let timerId;